import React from "react"
import Layout from "../../../components/layout"
// import CDPInputOutput from "../../images/svg/blogs/cdp-build/cdp-input-output.inline.svg";
import CDPSlicesOfPie from "!svg-react-loader!../../../images/svg/blogs/cdp-build/slices-of-pie.svg?name=CDPSlicesOfPieLogo";
import CDPArchitectIcon from "!svg-react-loader!../../../images/svg/blogs/cdp-build/cdp-input-output.inline.svg?name=CDPArchitectLogo";

export default function Blog({ data }) {
	return (
		<Layout>
			<article className="blog" id="cdpbuild">
				<header className="entry-header">
					<h1 className="entry-title">Working Collaboratively &amp; Winning the Largest Piece of the Pie</h1>
					<div className="entry-meta">
						<span className="sep">Posted on </span>
						<time className="entry-date">August 30, 2021</time>
					</div>
				</header>
				<div className="entry-content">
					<p>The agency world can be cut-throat.  Not only do you have multiple clients with extreme high pressure projects.  But you frequently need to collaobrate with your client's multiple partners.</p>

					<p>Working with partners can be challenging!  Often contensious!  Every partner is trying to grasp a larger piece of the pie, so they promise the client they can deliver X, Y, Z.  Some partners are blatant in their approach.  Domineering the conversation.  Interjecting at every possible opportunity.  What they choose to ignore (or fail to realize)?  They are annoying!  Not only for the other partners, but also for their own client.
					</p>

					<p>
When working for a client, my ultimate priority is to reduce their stress.  My client hired me to be part of their team.  My job is to provide them the top quality work in their best interest.  And if I can make the process less stressful and *gasp* even enjoyable?  They'll most likely keep me around!
					</p>

					<p>
How?  Work collaboratvely!
					</p>

					<p>A recent client of mine had a need for multiple marketing websites each with a registration form.  When visitors signed up providing an email address, they would receive a welcome email and be entered into a CRM campaign.  When they provided their phone number, they would be contacted by a call center.</p>

					<p>The client put out a bid to various agencies for pricing and 3 agencies were invited to the table.</p>

					<ul>
						<li>My team won the website development (Agency A).</li>
						<li>The CRM component was given to another agency (Agency B).</li>
						<li>And lastly the call center component was awarded to a third agency (Agency C).</li>
					</ul>
					<p>
						<div class="cdpslicesofpie">
							<CDPSlicesOfPie/>
						</div>
					</p>

					<p>
Now that we knew the main components, we started gathering requirements with a meeting.  We discussed immediate needs and started discussing future requirements.  This is when Agency C, clearly wanting a larger piece of the pie, started domineering the conversation to the point the meeting derailed.  We ran out of time so we scheduled another meeting.  Next meeting?  Again, derailed.  Spinning in cirlces and leading to nowhere.  The client must have been frustrated!  I know I was :)
					</p>

					<p>At this point, the only way to get everyone on the same page?  Le'ts start jotting down what we've discussed.</p>
					<p>
						My team had a clear idea the client needed a customer data platform (CDP). A central location as the main communication hub.  All websites will pass data to the CDP to be cleansed and transposed accordingly, and then passed off to appropriate parties.  
					</p>

					<p>
						So I quickly created and delivered an architect diagram.
					</p>
					<p>
						{/**http://www.clker.com/inc/svgedit/svg-editor.html#ungroup */}
						<div class="cdparchitecture">
							<CDPArchitectIcon />
						</div>
					</p>

					<p>We explained to the client, no matter who they give the work to, it's in their best interest to have a CDP.  This would save them money and easily support future features.  The client was thrilled with us taking the initiative to explain in simple terms what the team has already discussed.
					</p>

					<p>Who won the work?  Win for my team!</p>

					<p>Now that we were unstuck, we moved forward.  I asked both partners if they had an API specification.</p>

					<p>Agency B had limited API specs for the CRM component.  They had an endpoint that received a JSON object, but they had no naming conventions.  So, I quickly wrote up requirements for naming and data limitations and sent it over for their input.  They seemed happy with me taking the intiative and willingly followed my lead.</p>

					<p>Agency C provided a somewhat decent API doc.  They had fields indicating various rules including max character limits.  I had some feedback and also asked if we could use a status flag.  In this case, they could not support test records and didn't want us to send them any.</p>


					<p>Happy Social Coding!</p>
				</div>
			</article>
		</Layout>
	)
}

